// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-de-js": () => import("./../../../src/pages/de.js" /* webpackChunkName: "component---src-pages-de-js" */),
  "component---src-pages-en-js": () => import("./../../../src/pages/en.js" /* webpackChunkName: "component---src-pages-en-js" */),
  "component---src-pages-hr-js": () => import("./../../../src/pages/hr.js" /* webpackChunkName: "component---src-pages-hr-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

